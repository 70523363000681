// estilização da borda da tabela
var lineTable = $(".line-table-warranty-policy");

if (lineTable) {
    lineTable.last().css("border-bottom", "none");
    lineTable.last().css("padding-bottom", "45px");
    lineTable.first().css("padding-top", "45px");
}

//alinhamento colunas
var columnContainer = $(".columns-container");

for (var i = 0; i < columnContainer.length; i++) {
    var childCount = $(columnContainer[i]).children().length;

    if (childCount == 1) {
        $(columnContainer[i]).css("justify-content", "center");
    } else if (childCount == 5) {
        $(columnContainer[i]).css("justify-content", "space-between");
    } else {
        $(columnContainer[i]).css("justify-content", "flex-start");
    }
}
