export const setupDotBtns = (dotsArray, embla) => {
    const btns = document.querySelectorAll(".embla__button");
    if (dotsArray.length === 1) {
        dotsArray[0].style.display = "none";

        btns.forEach((btn) => {
            btn.style.display = "none";
        });
        return;
    }

    dotsArray.forEach((dotNode, i) => {
        dotNode.classList.add("embla__dot");
        dotNode.addEventListener("click", () => embla.scrollTo(i), false);
    });
};

export const generateDotBtns = (dots, embla, auto) => {
    const scrollSnaps = embla.scrollSnapList();
    if (scrollSnaps.length > 4) {
        const newScrollSnaps = scrollSnaps.filter(function (value) {
            if (value == NaN) {
                return false;
            } else {
                return value;
            }
        });
        const dotsFrag = document.createDocumentFragment();
        const dotsArray = newScrollSnaps.map(() => document.createElement("button"));

        dotsArray.forEach((dotNode) => {
            dotNode.addEventListener("click", () => {
                auto.stop();
                setTimeout(auto.reset, 15000);
            });
            dotsFrag.appendChild(dotNode);
        });
        dots.appendChild(dotsFrag);

        generateDotDivProgress(dotsArray);

        return dotsArray;
    } else {
        const dotsFrag = document.createDocumentFragment();
        const dotsArray = scrollSnaps.map(() => document.createElement("button"));

        dotsArray.forEach((dotNode) => dotsFrag.appendChild(dotNode));
        dots.appendChild(dotsFrag);

        generateDotDivProgress(dotsArray);

        return dotsArray;
    }
};

export const generateDotDivProgress = (dots) => {
    dots.map((item) => {
        const dotDiv = document.createElement("div");
        dotDiv.classList.add("progress");
        item.appendChild(dotDiv);
    });
    return dots;
};

export const selectDotBtn = (dotsArray, embla) => () => {
    const previous = embla.previousScrollSnap();
    const selected = embla.selectedScrollSnap();
    dotsArray[previous]?.classList.remove("is-selected");
    dotsArray[selected]?.classList.add("is-selected");
};
