// select personalizado
document.querySelectorAll(".select-wrapper:not(.has-subcategory)").forEach((elem) =>
    elem.addEventListener("click", () => {
        elem.querySelector(".select").classList.toggle("open");
    })
);

function addEventListenersToCustomOptions() {
    for (const option of document.querySelectorAll(".custom-option")) {
        option.addEventListener("click", function () {
            if (!this.classList.contains("selected")) {
                if (this.parentNode.querySelector(".custom-option.selected")) {
                    this.parentNode
                        .querySelector(".custom-option.selected")
                        .classList.remove("selected");
                }
                this.classList.add("selected");
                var customOptionHtml = $(this).html();
                var customOptionVal = $(this).val();

                if ($(".product-comparison-items").length) {
                    $(this).closest(".select").find(".select__trigger span").html(customOptionHtml);

                    var selectOption = $(this).closest(".select-wrapper").find("option");
                    var customSelect = $(this).closest(".select-wrapper").find("select");
                    selectOption.val(customOptionVal);
                    $(customSelect).trigger("change");
                } else {
                    $(this).closest(".select").find(".select__trigger span").html(customOptionHtml);

                    var selectOption = $(this).closest(".select-wrapper").find("option");

                    if ($("#product-reviews").length) {
                        selectOption.val($(this).data("value"));
                        changeRatingOrder($(this).data("value"));
                    } else {
                        selectOption.val(customOptionHtml);
                    }
                }
            }
        });
    }
}

addEventListenersToCustomOptions();

window.addEventListenersToCustomOptions = addEventListenersToCustomOptions;

selectWithSubcategories();

function selectWithSubcategories() {
    $(".category-option").on("click", function () {
        $(".has-subcategory .select").addClass("open");
        const id = $(this).attr("id");
        $(".custom-options > ul ul[data-id=" + id + "]").toggle();
        $(this).toggleClass("opened");
    });

    $(".subcategory-option p").on("click", function () {
        const id = $(this).parent().attr("id");
        $(this).toggleClass("opened");
        if ($(".products-list[data-id=" + id + "]").html() == "") {
            $.get(`/plugins/products-by-category/${id}`, (response) => {
                response.products.forEach(function (produto) {
                    $(".products-list[data-id=" + id + "]").append(
                        '<li class="custom-option" id="' +
                            produto.id +
                            '">' +
                            produto.name +
                            "</li>"
                    );
                });
            });
        }

        $(".products-list[data-id=" + id + "]").toggle();
    });

    $(document).on("click", ".has-subcategory .custom-option", function () {
        $(".custom-options .custom-option.selected").removeClass("selected");
        $(this).addClass("selected");
        $(".has-subcategory .select").removeClass("open");
        $(".has-subcategory .select__trigger span p").html($(this).html());
        $(".has-subcategory select[name=product] option").val($(this).attr("id"));
    });

    $(".form-container .has-subcategory .select__trigger").on("click", function () {
        $(".has-subcategory .select").toggleClass("open");
    });
}

function setProdutoIfFromProduct() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("product_id") && params.has("category_id")) {
        $.get(`/plugins/products-by-category/${params.get("category_id")}`, (response) => {
            let produto = response.products.find(
                (produto) => produto.id == params.get("product_id")
            );
            $(".has-subcategory .select__trigger span p").html(produto.name);
            $(".has-subcategory select[name=product] option").val(produto.id);
        });
    }
}

setProdutoIfFromProduct();
