export function navigateTo(link) {
    const a = document.createElement("a");
    a.href = link;
    a.click();
}

export function transformIdsToQueryParams(ids = []) {
    const query = ids.reduce((acc, id, idx) => {
        return (acc += `id${idx + 1}=${id}&`);
    }, "");

    return query.substring(0, query.length - 1);
}
