import EmblaCarousel from "embla-carousel";
import { setupPrevNextBtns, disablePrevNextBtns } from "./prevAndNextButtons";
import { setupDotBtns, generateDotBtns, selectDotBtn } from "./dotButtons";
import Autoplay from "embla-carousel-autoplay";

if ($(".embla").length) {
    const wrap = document.querySelector(".embla");
    const viewPort = wrap.querySelector(".embla__viewport");
    const nextBtn = wrap.querySelector(".embla__button--next");
    const prevBtn = wrap.querySelector(".embla__button--prev");
    const dots = wrap.querySelector(".embla__dots");

    const autoplay = Autoplay(
        { delay: 6000, stopOnInteraction: true },
        (emblaRoot) => emblaRoot.parentElement
    );

    const embla = EmblaCarousel(viewPort, { loop: true }, [autoplay]);

    const dotsArray = generateDotBtns(dots, embla, autoplay);
    const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
    const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);
    setupPrevNextBtns(prevBtn, nextBtn, embla, autoplay);
    setupDotBtns(dotsArray, embla);

    embla.on("select", setSelectedDotBtn);
    embla.on("select", disablePrevAndNextBtns);
    embla.on("init", setSelectedDotBtn);
    embla.on("init", disablePrevAndNextBtns);
}

import "./products-carousel.js";
import "./product-galery.js";
