//trocar abas
$("#online-store-btn").on("click", function () {
    $("#physical-store-btn").removeClass("selected");
    $(".store-map").removeClass("show");
    $(".online-store").addClass("show");
    $(this).addClass("selected");
});

$("#physical-store-btn").on("click", function () {
    $("#online-store-btn").removeClass("selected");
    $(".online-store").removeClass("show");
    $(".store-map").addClass("show");
    $(this).addClass("selected");
});

//modal e caixa de aviso
$("#technical-assistance button").on("click", function () {
    $("#protocol-modal").addClass("show");
});

$("#close-protocol-modal").on("click", function () {
    $("#protocol-modal").removeClass("show");
});

$("#alert-icon").on("click", function () {
    $("#warning-box").addClass("show");
});

$("body").click(function () {
    if ($("#warning-box").hasClass("show")) {
        $("#warning-box").removeClass("show");
    }
});

function fillProtocolModalResultContainer({ textos, NRATD, CLIENTE }) {
    $("#protocol-number").text(NRATD);

    const dates = textos.map((t) => t.DATA.replaceAll("-", "/"));
    const status = textos.map((t1) => t1.OBS);

    const datesHtml = dates.reduce(
        (acc, d) => acc + `<li><p>${d}</p></li>`,
        "<li><h4>Data</h4></li>"
    );
    $("#protocol-result-list ul:nth-of-type(1)").html(datesHtml);

    const statusHtml = status.reduce(
        (acc, d) => acc + `<li><p>${d}</p></li>`,
        "<li><h4>Status</h4></li>"
    );
    $("#protocol-result-list ul:nth-of-type(2)").html(statusHtml);

    $("#protocol-client-name").text(CLIENTE);
}

//handler resultado do protocolo
$("#search-protocol-btn").on("click", async () => {
    const protocol = $("#protocol-search-container input").val();
    if (!protocol) return;

    $.get(`/protocols/${protocol}`, (response) => {
        console.log(response);
        if (response.STATUS?.toLowerCase() === "ok") {
            fillProtocolModalResultContainer(response);
            $("#protocol-result-container").addClass("show");
        } else {
            $("#not-found-protocol").css("display", "block");
        }

        $("#search-protocol-btn").removeClass("show");
        $("#new-search-btn").addClass("show");
        $("#protocol-search-container input").css("visibility", "hidden");
        $("#protocol-alert-icon-container").css("visibility", "hidden");
        $("#protocol-search-label").css("visibility", "hidden");
    });
});

$("#new-search-btn").on("click", function () {
    $("#new-search-btn").removeClass("show");
    $("#protocol-result-container").removeClass("show");
    $("#search-protocol-btn").addClass("show");
    $("#protocol-search-container input").val("");
    $("#protocol-search-container input").css("visibility", "visible");
    $("#not-found-protocol").css("display", "none");
    $("#protocol-alert-icon-container").css("visibility", "visible");
    $("#protocol-search-label").css("visibility", "visible");
});

$("#where-to-buy .select__trigger").on("click", () => {
    $("#where-to-buy .select").toggleClass("open");
});

$(".custom-option").on("click", () => {
    $("#where-to-buy .select").toggleClass("open");
});
