export const setupPrevNextBtns = (prevBtn, nextBtn, embla, auto) => {
    function scrollPrev() {
        auto.stop();
        embla.scrollPrev();
        setTimeout(auto.reset, 15000);
    }

    function scrollNext() {
        auto.stop();
        embla.scrollNext();
        setTimeout(auto.reset, 15000);
    }

    prevBtn.addEventListener("click", scrollPrev, false);
    nextBtn.addEventListener("click", scrollNext, false);
};

export const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
    return () => {
        if (embla.canScrollPrev()) prevBtn.removeAttribute("disabled");
        else prevBtn.setAttribute("disabled", "disabled");

        if (embla.canScrollNext()) {
            nextBtn.removeAttribute("disabled");
        } else nextBtn.setAttribute("disabled", "disabled");
    };
};
