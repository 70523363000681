if ($(".product-doubt-container")) {
    $(".doubt-category div:first .category-container").addClass("selected-cat");

    if ($(".selected-cat")) {
        $(".selected-cat").next().addClass("show");
    }

    $(".doubt-container, .category-container").on("click", function () {
        var doubtsHeight = $(".category-doubts.show").height();
        $(".product-doubt-container").css("padding-bottom", doubtsHeight + 95 + "px");
    });

    var doubtsHeight = $(".category-doubts.show").height();
    $(".product-doubt-container").css("padding-bottom", doubtsHeight + 95 + "px");

    $(".category-container").on("click", function () {
        var id = $(this).data("id");

        if ($(".category-doubts.show")) {
            $(".category-doubts.show").removeClass("show");
        }
        if ($(".category-container.selected-cat")) {
            $(".category-container.selected-cat").removeClass("selected-cat");
            $(this).addClass("selected-cat");
        }

        $("#" + id).addClass("show");
        var doubtsHeight = $("#" + id).height();
        $(".product-doubt-container").css("padding-bottom", doubtsHeight + 95 + "px");
    });
}
