$(".item-container").on("click", function (event) {
    var id = $(this).data("id");
    $("#" + id).toggleClass("show");

    if ($("#" + id).hasClass("show")) {
        $("#div-" + id).css("border-radius", "20px 20px 0 0");
        $(this).find(".chevron-btn img").css("transform", "scaleY(-1)");
    } else {
        $("#div-" + id).css("border-radius", "32px");
        $(this).find(".chevron-btn img").css("transform", "scaleY(1)");
    }
});
