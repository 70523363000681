import { onlynumber, valueUS, valueBR } from "../utils/utils"

function getMinMaxValueByDataset(dataset_id, name) {
    const selector = `[data-id='${dataset_id}'][name=${name}]`;
    const element = document.querySelector(selector);
    return element.value || null;
}

function getMinMaxValueByID(id) {
    const element = document.getElementById(id);
    return element.value;
}

function setMinMaxValueByDataset(dataset_id, name, value) {
    const selector = `[data-id='${dataset_id}'][name=${name}]`;
    const element = document.querySelector(selector);
    element.value = value;
}

function setMinMaxValueByID(id, value) {
    const element = document.getElementById(id);
    element.parentElement.parentElement.parentElement.classList.add("filter-open");
    element.value = value;
}

function hideBasicFilters() {
    $(document.body).removeClass("stop-scrolling");
    $("#mobile-filter-options").slideUp();
}

function addFilter() {
    const filter_checkboxes = document.querySelectorAll(
        "#filter-options input[type=checkbox]:checked:not(#advancedFilterCheckbox)"
    );
    const mobile_filter_checkboxes = document.querySelectorAll(
        "#mobile-filter-options input[type=checkbox]:checked"
    );
    const advanced_filter_checkboxes = document.querySelectorAll(
        "#advancedFilter input[type=checkbox]:checked"
    );

    const isAdvancedFilterChecked = document.querySelector("#advancedFilterCheckbox").checked;

    const checkboxes = [];

    if ($(window).width() > 900) {
        checkboxes.push(...filter_checkboxes);

        if (isAdvancedFilterChecked) {
            checkboxes.push(...advanced_filter_checkboxes);
        }
        // console.log('filter-options');
    } else {
        checkboxes.push(...mobile_filter_checkboxes);
        // console.log('mobile-filter-options');
    }

    const filter = {};

    console.log(checkboxes);

    checkboxes.forEach((checkbox) => {
        const characteristic = checkbox.dataset.characteristic.toLowerCase();
        const item_value = checkbox.dataset.value.toLowerCase();

        if (!filter[characteristic]) {
            filter[characteristic] = [];
            filter[characteristic].push(item_value);
        } else {
            filter[characteristic].push(item_value);
        }
    });

    console.log(filter);

    const current_params = new URLSearchParams(window.location.search);
    const current_url = window.location.origin + window.location.pathname;
    const filter_url = new URL(current_url);

    filter_url.searchParams.set("page", 1);

    Object.entries(filter).forEach(([characteristic, values], index) => {
        filter_url.searchParams.set(characteristic, values);
    });

    getAllMinMax().forEach((item) => {
        filter_url.searchParams.set(item.id, item.value);
    });

    const isSomeAdvancedFilterChecked = advanced_filter_checkboxes.length > 0 || getAllMinMax().length > 0;
    if (isAdvancedFilterChecked && isSomeAdvancedFilterChecked) {
        filter_url.searchParams.delete("filtro_avancado");
        filter_url.searchParams.append("filtro_avancado", 1);
    }

    // console.log(decodeURIComponent(filter_url));
    location.href = decodeURIComponent(filter_url);
}

function checkOptions() {
    const params = new URLSearchParams(window.location.search);
    let values = [];
    for (const query_string_key of params.keys()) {
        if (["page", "filtro_avancado", "structure"].indexOf(query_string_key) == -1) {
            values = params.get(query_string_key).split(",");
            values.forEach((value) => {
                document.querySelectorAll(`[data-value="${value}"]`).forEach((element) => {
                    element.checked = true;
                });
                $("#filter-options").show();
                $("#filter-chevron").toggleClass("flip-image");
            });
        }
    }

    if (params.has("filtro_avancado")) {
        document.querySelector("#advancedFilterCheckbox").checked = params.has("filtro_avancado");
        showAdvancedFilter();
    }

    if (params.has("page")) {
        var page = params.get("page");
        $(".pages-list a").each(function () {
            if ($(this).data("page") == page) {
                $(this).addClass("current-page");
            }
        });
    } else {
        $(".pages-list a").first().addClass("current-page");
    }

    const advanced_filter_checkboxes = document.querySelectorAll(
        "#advancedFilter input[type=checkbox]:checked"
    );
    advanced_filter_checkboxes.forEach((element) => {
        if (!element.parentNode.closest(".filter-select").classList.contains("filter-open")) {
            element.parentNode.closest(".filter-select").classList.add("filter-open");
        }
    });
}

function showAdvancedFilter() {
    const checkbox = document.querySelector("#advancedFilterCheckbox");
    checkbox.checked ? $("#advancedFilter").slideDown() : $("#advancedFilter").slideUp();

    if (!checkbox.checked) {
        uncheckAdvancedOptions();
        // unsetAdvancedMaxMin();
    }
}

function hideAdvancedFilter() {
    const checkbox = document.querySelector("#advancedFilterCheckbox");
    checkbox.checked = false;
    $("#advancedFilter").slideUp();

    /*if (checkbox.checked) {
            uncheckAdvancedOptions();
        }*/
}

function uncheckAdvancedOptions(event) {
    if (event) {
        event.preventDefault();
    }

    const checkboxes = document.querySelectorAll("#advancedFilter input[type=checkbox]:checked");

    const current_params = new URLSearchParams(window.location.search);
    const current_url = window.location.origin + window.location.pathname;
    const filter_url = new URL(current_url);

    checkboxes.forEach((checkbox) => {
        current_params.delete(checkbox.dataset.characteristic.toLowerCase());
        checkbox.checked = false;
    });

    current_params.forEach((value, key) => {
        if (["page", "filtro_avancado", "structure"].indexOf(key) == -1)
            filter_url.searchParams.set(key, value);
    });

    // location.href = decodeURIComponent(filter_url);
    const new_url = decodeURIComponent(filter_url);

    unsetAdvancedMaxMin(new_url);
}

function scrollToFilter() {
    const filtroProdutos = document.getElementById("filtroProdutos");

    if (filtroProdutos) {
        const top = filtroProdutos.getBoundingClientRect().top + window.pageYOffset;

        const params = new URLSearchParams(window.location.search);
        const hasBasicFilter = Array.from(params).length > 0;
        const hasAdvancedFilter = params.has("filtro_avancado");

        if (hasBasicFilter || hasAdvancedFilter) {
            // window.scrollTo({top: top - 150, behavior: 'smooth'});
            window.scrollTo({ top: top - 150 });
        }
    }
}

function setMaxMin() {
    const current_params = new URLSearchParams(window.location.search);
    current_params.forEach((value, key) => {
        let firstThree = key.substring(0, 3);
        if (firstThree == "max" || firstThree == "min") {
            setMinMaxValueByID(key, value);
        }
    });
}

function unsetAdvancedMaxMin(current_url) {
    const filter_url = new URL(current_url);

    const elements = document.querySelectorAll("#advancedFilter input[type=text]");
    elements.forEach((element) => {
        element.value = "";
        element.parentNode.closest(".filter-select").classList.remove("filter-open");
        filter_url.searchParams.delete(element.id);
    });

    location.href = decodeURIComponent(filter_url);
}

$("input[name=min], input[name=max]").on('change', function () {
    const element = this;
    // function addMaxMinFilter(element) {
    const dataset_id = element.dataset.id;
    const min_id = `min_${dataset_id}`;
    const max_id = `max_${dataset_id}`;

    let min_value = getMinMaxValueByID(min_id);
    let max_value = getMinMaxValueByID(max_id);
    // console.log(max_value)

    // if (min_value != "" && max_value != "" && Number(min_value) > Number(max_value)) {
    //     [min_value, max_value] = [max_value, min_value];
    //     setMinMaxValueByID(min_id, min_value);
    //     setMinMaxValueByID(max_id, max_value);
    // }

    // console.log(`Min: ${min_value}, Max: ${max_value}`);

    const attributes = {
        characteristic_id: element.dataset.id,
        characteristic: element.dataset.characteristic,
        value: element.value,
        name: element.name,
    };

    const current_url = window.location.href;
    const filter_url = new URL(current_url);

    !min_value
        ? filter_url.searchParams.delete(min_id)
        : filter_url.searchParams.set(min_id, min_value);
    !max_value
        ? filter_url.searchParams.delete(max_id)
        : filter_url.searchParams.set(max_id, max_value);

    if (isElementChildOf(`#${max_id}`, "#filtroAvancado")) {
        filter_url.searchParams.delete("filtro_avancado");
        filter_url.searchParams.append("filtro_avancado", 1);
    }

    // console.log(decodeURIComponent(filter_url));
    setTimeout(() => {
        location.href = decodeURIComponent(filter_url);
    }, 100);
});

function isElementChildOf(child_element, parent_element) {
    const child = document.querySelector(child_element);
    const parent = document.querySelector(parent_element);
    return parent.contains(child)
}

function getAllMinMax() {
    const allMinMax = [];
    $("input[name=max], input[name=min]").each(function () {
        const element = $(this);

        if (element.val()) {
            allMinMax.push({
                id: element.attr("id"),
                value: element.val(),
            });
        }
    });

    return allMinMax;
}

$("#filter-characteristics").on("click", function () {
    $("#filter-chevron").toggleClass("flip-image");

    if ($(window).width() > 900) {
        $("#filter-options").slideToggle();
        if ($("#advancedFilter").is(":visible")) {
            $("#advancedFilter").slideToggle();
        } else {
            if ($("#advancedFilterCheckbox").is(":checked")) {
                $("#advancedFilter").show();
            }
        }
    } else {
        $(document.body).addClass("stop-scrolling");
        $("#mobile-filter-options").slideDown();
    }
});

$("input[name=min], input[name=max]").keypress(function (event) {
    onlynumber(event);
});

$("#btnApplyFilter").on("click", addFilter);
$("input[name=addfilter]").on("click", addFilter);
$("#btnHideBasicFilters").on("click", hideBasicFilters);
$("#advancedFilterCheckbox").on("click", showAdvancedFilter);
$("#linkUncheckAdvancedOptions, #imgUncheckAdvancedOptions").on("click", uncheckAdvancedOptions);

$(".filter-select-container").on("click", function () {
    $(this).parent().toggleClass("filter-open");
});

setMaxMin();
checkOptions();
scrollToFilter();

