export function onlynumber(evt) {
    let theEvent = evt;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    //let regex = /^[0-9.,]+$/;
    let regex = /^[0-9.]+$/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
}

export function valueUS(value) {
    if (value) {
      let value_us = value.replace(/\./g, '').replace(/\,/g, '.');
      return Number(value_us);
    }

    return 0;
}

export function valueBR(value, decimals = 0) {
    if (value) {
      return value.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals } )
    }

    return (0).toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals } );
}

