import { createModalDownload } from "../components/modal-download";

handleBlogHeaderWidth();

createModalDownload("#blog-download-title");

$(window).resize(function () {
    handleBlogHeaderWidth();
});

function handleBlogHeaderWidth() {
    if ($(".blog-posts-filters-container").width() > $(".blog-posts-header-container").width()) {
        $(".blog-posts-filters-container").css("width", "100%");
        $("#right-arrow-blog-categories").show();
    } else {
        $("#right-arrow-blog-categories").hide();
    }
}

var catInitialPosition;
if ($(".blog-posts-filters a").length) {
    catInitialPosition = $(".blog-posts-filters a").first().position().left;
}

$("#right-arrow-blog-categories").on("click", function (event) {
    event.preventDefault();
    $(".blog-posts-filters").animate(
        {
            scrollLeft: "+=200px",
        },
        "slow"
    );
});

$("#left-arrow-blog-categories").on("click", function (event) {
    event.preventDefault();
    $(".blog-posts-filters").animate(
        {
            scrollLeft: "-=200px",
        },
        "slow"
    );
});

$(".blog-posts-filters").on("scroll", function (event) {
    if ($(".blog-posts-filters a").first().position().left < catInitialPosition) {
        $("#left-arrow-blog-categories").show();
    } else if ($(".blog-posts-filters a").first().position().left == catInitialPosition) {
        $("#left-arrow-blog-categories").hide();
    }
});

// Perguntas e respostas
$(".doubt-title").on("click", function () {
    var id = `#${$(this).data("id")}`;
    const show = $(id).toggleClass("show").hasClass("show");
    $(this)
        .find("img")
        .css("transform", show ? "rotate(45deg)" : "rotate(0)");
});


if ($(".blog-post-more-posts .blog-posts-list").children().length == 0) {
    $(".blog-post .blog-post-more-posts-container").hide();
    $(".blog-post .black-container").hide();
}
  
if ($(window).width() < 1200) {
    if ($(".blog-post-more-posts .blog-posts-list").children().length > 2) {
        $(".blog-post-more-posts .blog-posts-list a:last-child").css(
        "display",
        "none"
        );
    }
}
  