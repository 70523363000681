export function createModalDownload(downloadButton) {
    $(downloadButton).on("click", handleOpenModalClick);
    $("#download-modal-button, #download-modal-close-icon").on("click", closeModal);

    const link_from_button = $(downloadButton).data("link");
    console.log(link_from_button);



    function handleOpenModalClick() {
        const link = !!link_from_button ? link_from_button : $("#blog-download-title").data("link");
        $("#download-modal-button").attr("href", link);
        openModal();
    }

    function openModal() {
        $(document.body).addClass("stop-scrolling");

        const modal = $("#download-modal-container");
        modal.css("display", "flex");

        setTimeout(() => {
            modal.css("opacity", 1);
        }, 0);
    }

    function closeModal() {
        $(document.body).removeClass("stop-scrolling");

        const modal = $("#download-modal-container");
        modal.css("opacity", 0);

        setTimeout(() => {
            modal.css("display", "none");
        }, 0);
    }
}
