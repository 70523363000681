import EmblaCarousel from "embla-carousel";
import { onThumbClick, followMainCarousel } from "./thumbButtons";

if($("#main-galery").length) {
  const mainWrap = document.getElementById("main-galery");
  const mainView = mainWrap.querySelector(".embla__viewport");
  
  const mainCarousel = EmblaCarousel(mainView, {
    selectedClass: "",
    loop: false,
    skipSnaps: false,
    dragFree: false,
    draggable: true,
  });

  const thumbCarouselWrap = document.getElementById("thumb-galery");
  const thumbCarouselView = thumbCarouselWrap.querySelector(".embla__viewport");
  const nextBtn = thumbCarouselWrap.querySelector(".embla__button--prev");
  const prevBtn = thumbCarouselWrap.querySelector(".embla__button--next");

  const thumbCarousel = EmblaCarousel(thumbCarouselView, {
    selectedClass: "",
    containScroll: "keepSnaps",
    dragFree: true
  });

  thumbCarousel.slideNodes().forEach((thumbNode, index) => {
    const onClick = onThumbClick(mainCarousel, thumbCarousel, index);
    thumbNode.addEventListener("click", onClick, false);
  });

  prevBtn.addEventListener('click', mainCarousel.scrollPrev, false)
  nextBtn.addEventListener('click', mainCarousel.scrollNext, false)
  prevBtn.addEventListener('click', thumbCarousel.scrollPrev, false)
  nextBtn.addEventListener('click', thumbCarousel.scrollNext, false)

  const syncThumbCarousel = followMainCarousel(mainCarousel, thumbCarousel);
  mainCarousel.on("select", syncThumbCarousel);
  thumbCarousel.on("init", syncThumbCarousel);
}