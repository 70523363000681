function addCloseModalClickListener() {
    $(".close-modal").on("click", function () {
        $(".modal-image").fadeOut();
    });
}

$(".open-image-modal").on("click", function () {
    const modalImage = $(this).data("image");
    $(".modal-image img").attr("src", modalImage);
    $(".modal-image").fadeIn("fast", addCloseModalClickListener);
});

$(".modal-image").on("click", (event) => {
    if (event.target.nodeName.toLowerCase() != "img") {
        $(".modal-image").fadeOut();
    }
});
