import { createSlider } from "../components/slider";

createSlider("#videos-container");

if($('.product-doubt-container')){    
    $('.category-container').on('click', function(){
        var id = $(this).data('id');
        
        if($('.category-doubts.show')){
            $('.category-doubts.show').removeClass('show');
        }
        if($('.category-container.selected-cat')){
            $('.category-container.selected-cat').removeClass('selected-cat');
            $(this).addClass('selected-cat')
        }
    
        $('#'+id).addClass('show');
        var doubtsHeight = $('#'+id).height(); 
        $('.product-doubt-container').css("padding-bottom", doubtsHeight+95+"px");
    });
}
