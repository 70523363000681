$(function () {
    $(".play-video").on("click", function () {
        const id = $(this).data("modal");
        const idPlugin = $(this).parent().attr("id");
        const videoContainerId = `#${idPlugin}`;
        $(`${videoContainerId} .thumb-image`).hide();
        $(`${videoContainerId} .player-icon`).hide();
        $(`${videoContainerId} .yt-video`).show();
        const urlVideo = "https://www.youtube.com/embed/" + id + "?rel=0&autoplay=1";
        $(`${videoContainerId} .yt-video`).attr("src", urlVideo);
    });
});

// Modal de vídeo
function closeModal() {
    $(".closeModal").on("click", function () {
        $(".modalVideo").fadeOut();
        $("#iframeVideoPlayer").remove();
    });
}

$(".abreModal").on("click", function () {
    const modal = $(this).data("modal");
    const urlVideo = `https://www.youtube.com/embed/${modal}?rel=0&controls=1&autoplay=1`;

    $("#embedResponsive").append(
        '<iframe class="embedVideo" id="iframeVideoPlayer" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    );

    $("#iframeVideoPlayer").attr("src", urlVideo);
    $(".modalVideo").fadeIn("fast", closeModal);
});

$(".modalVideo").on("click", (event) => {
    if (event.target.nodeName.toLowerCase() != "embedResponsive") {
        closeModal();
    }
});
