$(() => {
    if ($(window).width() > 900) {
        openSubCategory();
    } else {
        openSubCategoryMobile();
    }
    handleContainerSizes();
    var catInitialPosition;
    var dropdownInitialPosition;
    var firstExecution = true;

    if ($(".dropdown").first().length) {
        catInitialPosition = $(".cat-container").position().left;
    }

    $(window).resize(function () {
        handleContainerSizes();
    });

    $("#right-arrow-cat").on("click", function (event) {
        event.preventDefault();
        $(".render-categories").animate(
            {
                scrollLeft: "+=200px",
            },
            "slow"
        );
    });

    $("#left-arrow-cat").on("click", function (event) {
        event.preventDefault();
        $(".render-categories").animate(
            {
                scrollLeft: "-=200px",
            },
            "slow"
        );
    });

    $(".render-categories").on("scroll", function (event) {
        if ($(".cat-container").position().left < catInitialPosition) {
            $("#left-arrow-cat").show();
        } else if ($(".cat-container").position().left == catInitialPosition) {
            $("#left-arrow-cat").hide();
        }
    });

    $(".dropdown-content").on("classChange", function (event) {
        var widthCat = $(".cat-container").width();
        if ($(".dropdown-content").hasClass("show")) {
            if ($(".dropdown-content.show .subcategory").length && firstExecution == true) {
                dropdownInitialPosition = $(".dropdown-content.show .subcategory")
                    .first()
                    .position().left;
                firstExecution = false;
            }
            if ($(".dropdown-content.show > div").width() > widthCat) {
                $("#right-arrow-dropdown").show();
            }
            if (
                $(".dropdown-content.show .subcategory").first().position().left <
                dropdownInitialPosition
            ) {
                $("#left-arrow-dropdown").show();
            }
        } else {
            $("#right-arrow-dropdown").hide();
            $("#left-arrow-dropdown").hide();
        }
    });

    $("#right-arrow-dropdown").on("click", function (event) {
        event.preventDefault();
        $(".dropdown-content.show").animate(
            {
                scrollLeft: "+=200px",
            },
            "slow"
        );
    });

    $("#left-arrow-dropdown").on("click", function (event) {
        event.preventDefault();
        $(".dropdown-content.show").animate(
            {
                scrollLeft: "-=200px",
            },
            "slow"
        );
    });

    $(".dropdown-content").on("scroll", function (event) {
        if (
            $(".dropdown-content.show .subcategory").first().position().left <
            dropdownInitialPosition
        ) {
            arrowsPosition();
            $("#left-arrow-dropdown").show();
        } else if ($(".cat-container").position().left == catInitialPosition) {
            $("#left-arrow-dropdown").hide();
        }
    });

    function handleContainerSizes() {
        $(".dropdown").last().css("padding-right", "0px");
        var widthCat = $(".cat-container").width();
        var containerWidth = $(".categories-subcategories .container").width();

        if (widthCat > containerWidth) {
            $(".categories-subcategories .line").css("width", "100%");
            if ($(".dropdown-content").hasClass("show")) {
                $(".render-categories").css("margin", "0 0 75px");
            } else {
                $(".render-categories").css("margin", "0 0 15px");
            }
            $(".dropdown-content").css("width", containerWidth);
            $("#right-arrow-cat").show();
        } else {
            $(".categories-subcategories .line").css("width", widthCat);
            $(".dropdown-content").css("width", widthCat);
            $("#right-arrow-cat").hide();
        }

        if ($(window).width() < 900) {
            $(".dropdown-content").css("width", "100%");

            if (widthCat < containerWidth) {
                $(".categories-subcategories .container").css("padding", "50px 0 8%");
                $(".categories-subcategories .title h1").css("margin-left", "0");
            }
        }
    }

    function arrowsPosition() {
        var catPosition = $(".cat-container").offset();
        var widthCat = $(".cat-container").width();
        $("#right-arrow-dropdown").offset({ left: catPosition.left + widthCat + 10 });
        $("#left-arrow-dropdown").offset({ left: catPosition.left - 55 });
    }

    function openSubCategory() {
        $(".dropbtn").on("click", function (event) {
            if ($(this).parent().prop("tagName") == "DIV") {
                var id = $(this).data("id");
                if (event.target.classList.contains("selected")) {
                    $(".show").removeClass("show").trigger("classChange");
                    $(".selected").removeClass("selected");
                    $(".render-categories").css("margin-bottom", "15px");
                } else {
                    if ($(".show").length) {
                        $(".show").removeClass("show").trigger("classChange");
                    }
                    if ($(".selected").length) {
                        $(".selected").removeClass("selected");
                    }
                    $("#" + id)
                        .toggleClass("show")
                        .trigger("classChange");
                    event.target.classList.toggle("selected");
                    if ($(".selected").length) {
                        $(".render-categories").css("margin-bottom", "75px");
                    }
                }
                arrowsPosition();
            }
        });
    }

    function openSubCategoryMobile() {
        $(".dropbtn").on("click", function () {
            if ($(this).parent("a").length == 0) {
                var id = $(this).data("id");
                $("#" + id).toggleClass("open-subcategory");
                $(".dropdown-content").animate(
                    {
                        left: "0",
                    },
                    600
                );
                $("body").css("overflow-y", "hidden");
                $(".bg-overlay").toggleClass("show");
            }
        });

        $(".close-subcategory")
            .add(".bg-overlay")
            .on("click", function () {
                closeSubCategoryMobile();
            });
    }

    function closeSubCategoryMobile() {
        $(".dropdown-content").animate(
            {
                left: "-100vw",
            },
            600,
            function () {
                $(".open-subcategory").removeClass("open-subcategory");
            }
        );
        $("body").css("overflow-y", "auto");
        $(".bg-overlay").toggleClass("show");
    }
});
