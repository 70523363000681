// abas dos formulários
$("#contact-forms .forms-tabs li").on("click", function () {
    var selectedForm = $(this).data("form");

    if ($(this).hasClass("selected")) {
        $(this).removeClass("selected");
        $("#" + selectedForm).removeClass("show");
        return;
    }

    if ($("#contact-forms li").hasClass("selected")) {
        $("#contact-forms li").removeClass("selected");
        $(".form-container").removeClass("show");
    }

    $(this).addClass("selected");
    $("#" + selectedForm).addClass("show");
});

//erro - abrir form que foi enviado
if ($(".form-container").children().attr("data-success") != null) {
    var elementForm = $('.form-container form[data-success="False"]').parent();
    var id = elementForm.attr("id");
    elementForm.addClass("show");
    $('[data-form="' + id + '"]').addClass("selected");
}

//modal de sucesso do envio
if ($('.form-container form[data-success="True"]').length > 0) {
    var elementForm = $('.form-container form[data-success="True"]').parent();
    var id = elementForm.attr("id");
    elementForm.addClass("show");
    $('[data-form="' + id + '"]').addClass("selected");
    $(".modal-contact-success").toggleClass("show");
}

$(".close-modal-success").on("click", function () {
    $(".modal-contact-success").toggleClass("show");
});

// adicionando nome do arquivo no anexo
$("input[type=file]").bind("change", function () {
    var fileName = "";
    fileName = $(this).val();
    $(this).next().html(fileName.split("\\").pop());
});

// selects diferentes para input radio do form comercial
$("#comercial input[type=radio]").on("click", function () {
    var radioValue = $(this).val();
    radioInputHandler(radioValue);
});

if ($("#comercial input[type=radio]").attr("checked")) {
    var radioValue = $("#comercial input[type=radio][checked]").val();
    radioInputHandler(radioValue);
}

function radioInputHandler(value) {
    if (value == "cnpj") {
        $(".select-comercial_option_cnpj").addClass("show");
        $(".select-comercial_option_cpf").removeClass("show");
    } else {
        $(".select-comercial_option_cnpj").removeClass("show");
        $(".select-comercial_option_cpf").addClass("show");
    }
}

if ($('.width-50 input[name="serial_number"]').length) {
    $(".width-50").has('input[name="serial_number"]').css("align-self", "start");
}

// Mascaras dos inputs

$(document).ready(function () {
    const cpfCnpjMaskOptions = {
        onKeyPress: function (text, e, field, options) {
            const masks = ["000.000.000-000", "00.000.000/0000-00"];
            const mask = text.length > 14 ? masks[1] : masks[0];
            $(".cpf_cnpj_input").mask(mask, cpfCnpjMaskOptions);
        },
    };

    $(".cpf_cnpj_input").mask("000.000.000-00", cpfCnpjMaskOptions);

    const dynamicPhoneOptions = {
        onKeyPress: function (text, e, field, options) {
            const masks = ["(00) 0000.00000", "(00) 00000.0000"];
            const mask = text.length > 14 ? masks[1] : masks[0];
            $(".dynamic_phone_input").mask(mask, dynamicPhoneOptions);
        },
    };

    $(".dynamic_phone_input").mask("(00) 0000.0000", dynamicPhoneOptions);
});

// Validações de campos

$("#contact-forms .forms-tabs li").on("click", function () {

    let activeForm = $(".show form").attr("id")
    let inputs = $(".show form input, .show form textarea");
    let selectOptions = $(".show form .select .custom-options span");
    let selectField = $(".show form .select");
    let productOptions = $(".show .has-subcategory .custom-options ul p");
    let submitButton = $(".show form button");
    let checkbox = $(".show form fieldset:has(input[name='user_type'])");
    let checkboxOptions = $(".show form fieldset input[name='user_type']");


    // Bloqueia botão de envio

    $(submitButton).css('pointer-events', 'none')


    let inputValues = {};

    if (activeForm) {

        // Checa of valores vazios no formulário ativo

        inputs.each(function (index, input) {
            if (
                input.type !== "hidden" &&
                input.name !== "file" &&
                input.name !== "g-recaptcha-response" &&
                input.name !== "instagram" &&
                input.name !== "complement" &&
                !(input.name == "phone" && activeForm !== "marketing-form")
            ) {
                if (input.type === "radio") {
                    let fieldset = $(input).closest("fieldset");
                    let checkedRadio = fieldset.find(":checked");
                    if (checkedRadio.length > 0) {
                        inputValues[input.name] = true;
                    } else {
                        inputValues[input.name] = false;
                    }
                } else {
                    inputValues[input.name] = input.value ? true : false;
                }
            }
        });
    }

    inputValues["select"] = $(selectOptions).hasClass("selected")

    if ($(".show .has-subcategory").length > 0) {
        inputValues["select_product"] = $(productOptions).hasClass("opened")
    }


    //Função para validar os campos vazios e liberar o botão

    function validateFields() {

        let warningMessages = $(".show form .warningMessage")

        let allInputsValid = true;
        for (let key in inputValues) {
            if (!inputValues[key]) {
                allInputsValid = false;
                break;
            }
        }

        if (allInputsValid && warningMessages.length == 0) {

            $(submitButton).css('pointer-events', 'all');
            $(".show form").next(".warningMessage").remove()

        } else {

            $(".show form").next(".warningMessage").remove()

            $(submitButton).css('pointer-events', 'none')

            let p = document.createElement("p")
            $(p).addClass("warningMessage")
            $(p).html("Você precisa preencher todos os campos")

            $(".show form").after(p);
        }
    }


    // Função para criar a mensagem de aviso

    function createWarningMessage(element, message) {
        let warningSpan = $(element).next("span");
        let ul = document.createElement("ul");
        let li = document.createElement("li");
        $(li).html(message);
        $(ul).addClass("warningMessage");
        $(ul).append(li);
        $(warningSpan).append(ul);
    }

    // Função para limpar mensagem de aviso

    function clearWarningMessage(element) {
        $(element).next().find(".warningMessage").remove()
    }

    // Função para validar CPF

    function validateCPF(cpf) {
        var sum;
        var rest;

        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999") {
            return false;
        }

        sum = 0;
        for (i = 1; i <= 9; i++) {
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }
        rest = (sum * 10) % 11;

        if ((rest == 10) || (rest == 11)) {
            rest = 0;
        }
        if (rest != parseInt(cpf.substring(9, 10))) {
            return false;
        }

        sum = 0;
        for (i = 1; i <= 10; i++) {
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }
        rest = (sum * 10) % 11;

        if ((rest == 10) || (rest == 11)) {
            rest = 0;
        }
        if (rest != parseInt(cpf.substring(10, 11))) {
            return false;
        }
        return true;
    }


    // Adiciona aviso de vazio após preenchimento para INPUTS

    $(inputs).filter(function() {
        return inputValues.hasOwnProperty(this.name);
      }).blur(function () {

        if (
            this.name !== "file"
            && this.name !== "g-recaptcha-response"
            && this.name !== "complement"
            && this.name !== "instagram"
        ) {
            if (!$(this).val()) {

                let message = "Este campo é obrigatório."

                clearWarningMessage(this)

                createWarningMessage(this, message)

                inputValues[this.name] = false;

            } else {
                inputValues[this.name] = true;
                clearWarningMessage(this)
            }
        }

        // Verifica se o campo CHECKBOX e SELECT já foram preenchidos e cria alarme

        if (this.name !== "user_type") {

            let messageSelect = "Este campo é obrigatório."

            if (!inputValues["select"]) {
                clearWarningMessage(selectField)
                createWarningMessage(selectField, messageSelect)
            } else {
                clearWarningMessage(this)
            }

            let messageCheckbox = "Este campo é obrigatório."

            if (!inputValues["user_type"]) {
                clearWarningMessage(checkbox)
                createWarningMessage(checkbox, messageCheckbox)
            } else {
                clearWarningMessage(this)
            }
        }

        validateFields()
    });

    // Remove aviso de vazio após preenchimento para CHECKBOX

    $(checkboxOptions).click(function () {
        setTimeout(() => {
            clearWarningMessage(checkbox)

            validateFields()
        }, 500);
    });

    // Adiciona ou remove aviso de vazio após preenchimento para SELECT

    $(selectOptions).click(function () {
        setTimeout(() => {
            clearWarningMessage(selectField)

            inputValues["select"] = $(selectOptions).hasClass("selected")
            validateFields()
        }, 500);
    });

    $(productOptions).click(function () {
        setTimeout(() => {
            clearWarningMessage(selectField)

            inputValues["select_product"] = $(productOptions).hasClass("opened")
            validateFields()
        }, 1000);
    });

    // Valida informações digitadas nos inputs selecionados

    $(inputs).blur(function () {

        let fieldsToValidate = {
            document_number: ["number", 11],
            zip_code: ["number", 8],
            phone: ["number", 10],
            cell_phone: ["number", 11],
            email: ["email"],
            email_confirmation: ["email"]
        }

        if (this.name in fieldsToValidate) {

            let type = fieldsToValidate[this.name][0]
            let minChar = fieldsToValidate[this.name][1]

            // Valida os campos numéricos

            if (type == "number") {

                let fieldValue = $(this).val().replace(/\D/g, '');

                if (fieldValue.length < minChar && fieldValue.length > 0) {

                    let message = "Este campo precisa ter no mínimo " + minChar + " números."

                    clearWarningMessage(this)

                    createWarningMessage(this, message)

                } else {

                    clearWarningMessage(this)

                }

                // Valida o campo CPF ou CNPJ

                if (this.name == "document_number") {
                    if (fieldValue.length == minChar) {
                        if (!validateCPF(fieldValue)) {
                            let message = "Digite um CPF válido."
                            clearWarningMessage(this)
                            createWarningMessage(this, message)
                        }
                    } else if (fieldValue.length > minChar && fieldValue.length < 14) {
                        let message = "O CNPJ deve conter 14 dígitos."
                        clearWarningMessage(this)
                        createWarningMessage(this, message)
                    }
                }

            }

            // Valida os campos de email

            if (type == "email") {

                let emailValue = $(this).val();
                let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;
                if (!emailValue.match(emailPattern)) {
                    if (emailValue.length > 0) {
                        let message = "Digite um email válido."
                        createWarningMessage(this, message)
                    }

                } else if (this.name == "email_confirmation") {

                    let firstEmailFieldValue = $(".show form input[name='email']").val()

                    if (emailValue.length > 0) {
                        if (emailValue !== firstEmailFieldValue) {
                            let message = "Os emails precisam ser iguais."
                            createWarningMessage(this, message)
                        }
                    }

                }

            }
        }

        validateFields()
    });

    // Insere alerta para campos vazios após o preenchimento de TEXTAREA
    // Check de fieldset para campos input que são RADIO

    $(".show form textarea[name='message']").blur(function () {
        Object.keys(inputValues).forEach(function (key) {
            let value = inputValues[key];
            let element = $(`.show form input[name='${key}']`);
            let message = "Este campo é obrigatório.";

            if (!value) {
                if (element.is(':radio')) {
                    let fieldset = element.closest('fieldset');
                    let name = element.attr('name');
                    let hasWarned = fieldset.data(`warned-${name}`);

                    if (!hasWarned) {
                        clearWarningMessage(fieldset)
                        createWarningMessage(fieldset, message);
                        fieldset.data(`warned-${name}`, true);
                    }
                } else {
                    clearWarningMessage(element)
                    createWarningMessage(element, message);
                }
            }
        });
    });

    if (activeForm) {

        document.querySelector(".show form textarea[name='message']").addEventListener("mouseout", function () {

            if (this.value) {

                inputValues[this.name] = true;
                clearWarningMessage(this)

            }

            validateFields();
        });
    }
});