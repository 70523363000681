import { navigateTo } from "../utils/url";

function closeSearch() {
    $("#search-container").removeClass("active");
    $(document.body).removeClass("stop-scrolling");
    $(".search-input-container > #search-input").val("");
}

function openSearch() {
    $("#search-container").addClass("active");
    $(document.body).addClass("stop-scrolling");

    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("q")) {
        $(".search-input-container > #search-input").val(searchParams.get("q"))
    }

    setTimeout(() => {
        $(".search-input-container > #search-input").focus();
    }, 0);
}

$("#open-search").on("click", openSearch);
$("#close-search").on("click", closeSearch);

$("#search-input")
    .on("input", (event) => {
        $("search-label").html(`Pesquisando por <strong>${event.target.value}.</strong>`);
    })
    .on("keypress", (event) => {
        if (event.key === "Enter" && event.target.value !== "") {
            navigateTo(`/search?q=${event.target.value}`);
        }
    }).keyup(function(event) {
        if (event.keyCode == 27) {
            closeSearch()
        }
      });;

$("#search-input-img").on("click", (event) => {
    let value = $("#search-input").val();
    if (value) {
        window.location.href = `/search?q=${value}`;
    }
});

$("#search-container").on("click", function (ev) {
    if (ev.target.id == "search-container") closeSearch();
});

// Search results
$("#clean-search-results-input").on("click", (event) => {
    $("#search-results-input").val("").trigger("focus");
    $("#clean-search-results-input").hide();
});

$("#search-results-input-img").on("click", (event) => {
    const value = $("#search-results-input").val();
    if (value) {
        window.location.href = `/search?q=${value}`;
    }
});

function hideCleanButton(event) {
    const search = $("#search-results-input").val();

    if (search && search.length > 0) {
        $("#clean-search-results-input").show();
    } else {
        $("#clean-search-results-input").hide();
    }
}

$("#search-results-input").keyup(hideCleanButton);

$(document).ready(function() {
    setTimeout(function() {
        $("#search-results-input").focus()
    }, 0);

    hideCleanButton();
});
