import EmblaCarousel from "embla-carousel";
import { setupPrevNextBtns, disablePrevNextBtns } from "./prevAndNextButtons";
import { setupDotBtns, generateDotBtns, selectDotBtn } from "./dotButtons";

if($(".products-carousel").length) {
    const wrapProduct = document.querySelector(".products-carousel");
    const viewPortProduct = wrapProduct.querySelector(".embla__viewport");
    const nextBtnProduct = wrapProduct.querySelector(".embla__button--prev");
    const prevBtnProduct = wrapProduct.querySelector(".embla__button--next");
    const dotsProduct = wrapProduct.querySelector(".embla__dots");
    
    const emblaProduct = EmblaCarousel(viewPortProduct, { loop: true });
    
    const dotsArrayProduct = generateDotBtns(dotsProduct, emblaProduct);
    const setSelectedDotBtnProduct = selectDotBtn(dotsArrayProduct, emblaProduct);
    const disablePrevAndNextBtnsProduct = disablePrevNextBtns(prevBtnProduct, nextBtnProduct, emblaProduct);
    
    setupPrevNextBtns(prevBtnProduct, nextBtnProduct, emblaProduct);
    setupDotBtns(dotsArrayProduct, emblaProduct);
    
    emblaProduct.on("select", setSelectedDotBtnProduct);
    emblaProduct.on("select", disablePrevAndNextBtnsProduct);
    emblaProduct.on("init", setSelectedDotBtnProduct);
    emblaProduct.on("init", disablePrevAndNextBtnsProduct);
}