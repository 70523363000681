export function createSlider(slider) {
    const mSlider = $(slider);

    let mouseDown = false;
    let startX, scrollLeft;

    const startDragging = (e) => {
        mouseDown = true;
        startX = e.pageX - mSlider.offsetLeft;
        scrollLeft = mSlider.scrollLeft;
    };

    const stopDragging = () => {
        mouseDown = false;
    };

    const dragging = (e) => {
        e.preventDefault();
        if (!mouseDown) return;

        const x = e.pageX - mSlider.offsetLeft;
        const scroll = x - startX;
        mSlider.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    mSlider.on("mousemove", dragging);
    mSlider.on("mousedown", startDragging, false);
    mSlider.on("mouseup", stopDragging, false);
    mSlider.on("mouseleave", stopDragging, false);
}
