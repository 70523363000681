const createLocalStorage = function () {
    function set(variavel, valor) {
        valor = JSON.stringify(valor);
        localStorage.setItem(variavel, valor);
    }

    function get(variavel) {
        let valor = localStorage.getItem(variavel);

        if (valor) {
            return JSON.parse(valor);
        }

        return null;
    }

    function remove(variavel) {
        localStorage.removeItem(variavel);
    }

    function has(variavel) {
        return !!localStorage.getItem(variavel);
    }

    return { set, get, remove, has };
};

export default createLocalStorage;
