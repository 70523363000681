import { transformIdsToQueryParams, navigateTo } from "../utils/url";
import createLocalStorage from "../utils/localstorage";

const storage = createLocalStorage();

let productsIds = [];
let categoryId = 0;
const COMPARISON_LIMIT = 3;

function handleCompareButtonClick() {
    const path = $("#banner-compare-products-button").data("link");
    const ids = productsIds.join(",");
    const params = `ids=${ids}&category_id=${categoryId}`;
    navigateTo(`${path}?${params}`);
    storage.remove('productsIds');
}

function handleCompareCheckboxChange() {
    const isChecked = $(this).is(":checked");
    const productId = $(this).data("product-id");
    const isOnLimit = productsIds.length === COMPARISON_LIMIT;

    if (isChecked) {
        if (isOnLimit) {
            $(this).prop('checked', false);
            return false;
        }
        categoryId = $(this).data("category-id");
        handleCompareCheckboxChecked(productId);
    } else {
        handleCompareCheckboxUnchecked(productId);
    }
    $("#banner-compare-products-selected-text span").text(productsIds.length);
}

function handleCompareCheckboxChecked(productId) {
    const idIndex = productsIds.indexOf(productId);

    if (idIndex === -1) {
        productsIds.push(productId);
        categoryId = categoryId;
        storage.set('productsIds', productsIds);
        if (productsIds.length > 0) {
            $("#banner-compare-products").removeClass("close");
        }
    }
}

function handleCompareCheckboxUnchecked(productId) {
    const idIndex = productsIds.indexOf(productId);

    if (idIndex !== -1) {
        productsIds.splice(idIndex, 1);
        storage.set('productsIds', productsIds);

        if (productsIds.length === 0) {
            $("#banner-compare-products").addClass("close");
        }
    }
}

$("#banner-compare-products-button").on("click", handleCompareButtonClick);
$(".compare-product-checkbox").on("change", handleCompareCheckboxChange);

$("#banner-compare-products-clean-button").on("click", function() {
    productsIds = [];
    storage.remove('productsIds');

    const checkboxes = $(".products-list .product-more-info input.compare-product-checkbox");

    checkboxes.each(function () {
        $(this).prop("checked", false);
    })

    $("#banner-compare-products").addClass("close");
})

$(function() {
    if (storage.has('productsIds')) {
        productsIds = storage.get('productsIds')
    }

    if (productsIds.length > 0) {
        $("#banner-compare-products").removeClass("close");
        $("#banner-compare-products-selected-text span").text(productsIds.length);

        const checkboxes = $(".products-list .product-more-info input.compare-product-checkbox");
        checkboxes.each(function () {
            let isIdInList = productsIds.includes(Number($(this).data('productId')));
            if (isIdInList) {
                $(this).prop("checked", true);
            }
        })
    }
});
