function closeMenu() {
    $(document.body).removeClass("stop-scrolling");
    $("#side-menu").slideToggle();
    $("#side-menu .menu-container").slideToggle();
    $("#menu-logo").fadeOut("slow");
    $("#close-menu").fadeOut();
    $(".side-menu .menu-container nav ul li").fadeOut("slow");
}

function openMenu() {
    var delayTime = 100;
    $(document.body).addClass("stop-scrolling");
    $("#side-menu").slideToggle();
    $("#side-menu .menu-container").slideToggle();
    $("#menu-logo").delay(200).fadeIn("slow");
    $("#close-menu").delay(150).fadeIn();
    $(".side-menu .menu-container nav ul li").each(function () {
        $(this).delay(delayTime).fadeIn("slow");
        delayTime += 50;
    });
}

$("#open-menu").on("click", openMenu);
$("#close-menu").on("click", closeMenu);
$("#side-menu").on("click", function (ev) {
    if (ev.target.id == "side-menu") {
        closeMenu();
    }
});
